.TxSignerOverview {

}
  .TxSignerOverview__titleBar {
    margin-bottom: 0.5em;
  }
  .TxSignerOverview__titleBar__title {
    font-size: $s-scale-3;
    margin-bottom: 0.5em;
  }
  @include r-media(l) {
    .TxSignerOverview__titleBar {
      @include S-flex-row;
    }
    .TxSignerOverview__titleBar__title {
      @include S-flexItem-share;
      margin-bottom: 0;
    }
    .TxSignerOverview__titleBar__reset {
      @include S-flexItem-noFlex;
    }
  }
