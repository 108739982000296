.TransactionSigner {
}
  .TransactionSigner__import {
    margin-bottom: 2em;
  }
  .TransactionSigner__overview {
    margin-bottom: 2em;
  }
  .TransactionSigner__keys {
    margin-bottom: 2em;
  }
  .TransactionSigner__result {
    margin-bottom: 2em;
  }
  .TransactionSigner__result :last-child {
    margin-bottom: 0;
  }

  .TransactionSigner__details {
    margin-bottom: 2em;
  }
    .TransactionSigner__details__title {
      font-size: $s-scale-3;
      margin-bottom: 0.5em;
    }
    .TransactionSigner__details__tree {
    }
