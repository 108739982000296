.xdrInput {
  margin-bottom: 2em;
}
  .xdrInput__input {
  }
    .xdrInput__input__textarea {
      display: block;
      min-width: 4em;
      width: 36em;
      max-width: 100%;
      min-height: 12em;
      margin-bottom: 0;
    }

  .xdrInput__message {
    @include S-flex-row;
    align-items: center;
    min-height: 2em;
    padding: 0.25em 0.25em;
  }
    .xdrInput__message__alert {
      color: $s-color-alert;
      margin-bottom: 0;
    }
    .xdrInput__message__success {
      color: $s-color-success;
      margin-bottom: 0;
    }
