.EndpointPicker__section {
  margin-bottom: 2em;
}
.EndpointPicker__section:last-child {
  margin-bottom: 0;
}
.EndpointPicker__section__title {
  font-size: $s-scale-3;
  margin: 0 0.857em .25em 0.857em;
}
@include r-media(m) {
  .EndpointPicker {
    @include S-flex-row;
  }
  .EndpointPicker__section {
    @include S-flexItem-noFlex;
    margin-right: 2em;
    margin-bottom: 0;
  }
  .EndpointPicker__section:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
}
