.pageIntro {
  margin-top: 2em;
  margin-bottom: 2em;
  padding: 1em;

  background: $lightestGray;
  border-radius: $s-var-radius;
}
  .pageIntro :last-child {
    margin-bottom: 0;
  }
