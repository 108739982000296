.AccountCreator {
}
  .AccountCreator__container {
    max-width: 35em;
  }
  .AccountCreator__generator__table {
    margin-top: 1em;
    margin-bottom: 0.5em;
  }
  .AccountCreator__lead {
    margin-top: 1em;
  }
  .AccountCreator__section {
    padding-top: 2em;
    padding-bottom: 2em;
  }
  .AccountCreator__separator {
    border-bottom: 1px solid $gray;
  }
  .AccountCreator__friendbot__alert {
    margin-top: 1em;
  }
  .AccountCreator__spaceTop {
    margin-top: 1em;
  }
  .AccountCreator__label {
    margin-bottom: 0.2em;
  }
  .AccountCreator__note--alert {
    color: #d12;
  }
