.HelpMark {
  display: inline-block;
  height: 1.25em;
  width: 1.25em;
  vertical-align: middle;
}
.HelpMark__circle {
  fill: $s-color-neutral6;
}
.HelpMark__questionMark {
  fill: $s-color-neutral9;
}
.HelpMark:hover .HelpMark__circle {
  fill: $purple;
}
