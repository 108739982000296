.TxSignerKeys__title {
  font-size: $s-scale-3;
  margin-bottom: 0.5em;
}

.TxSignerKeys__signBipPath {
  margin-top: 0.5em;

  button {
    &:not(:last-child) {
      margin-right: 0.5em;
    }
  }
}
