.picker {

}
  .picker--textInput {
    width: 100%;
  }
  .picker--textarea {
    display: block;
    width: 100%;
    max-width: 100%;
    min-height: 10em;
  }
  .picker--breakContent {
    word-break: break-all;
  }

  // Useful to add some margin to the bottom when there is another picker
  // directly below
  .picker--spaceBottom {
    margin-bottom: 0.5em;
  }

  .picker__errorMessage {
    margin: 0.35em 0 0.65em 0;
    color: $s-color-alert;
  }
