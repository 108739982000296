.TreeView {

}
  .TreeView__child {
    border-left: 1em solid #F2F2F2;
  }
  .TreeView__child:hover {
    border-left-color: $lightPurple;
  }
  .TreeView__row {
    padding: 0.15em 0.5em;
  }
  .TreeView__row:hover {
    background: $s-color-neutral8;
  }
    .TreeView__row span {
      word-break: break-all
    }
