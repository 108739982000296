.TxSignerImport {

}
  .TxSignerImport__title {
    font-size: $s-scale-3;
    margin-bottom: 0.5em;
  }
  .TxSignerImport__error {
    color: red;
  }
