.EndpointSetup__url {
  word-break: break-all;
}
  .EndpointSetup__url__method {
    display: inline-block;
    padding: 0.25em 0.5em;
    margin-right: 0.5em;

    background: $s-color-neutral8;
    border-radius: $s-var-radius;
  }

.EndpointSetup__title {
  font-size: $s-scale-3;
  margin: 0 0.857em .25em 0.857em;
}
  .EndpointSetup__streaming__checkbox {
    cursor: pointer;
    margin-right: 0.75em;
  }
  .EndpointSetup__streaming__title {
    cursor: pointer;
  }
