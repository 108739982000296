.NetworkPicker {
  position: relative;
  align-self: flex-start;
}
.NetworkPicker__buttonGroup {
  // justify-content: flex-end;
}
.NetworkPicker__button {
  font-size: $s-scale-5;
  padding: 0.2em 1.5em;
}
.NetworkPicker__url {
  position: absolute;
  bottom: -26px;
  right: 0;
  white-space: nowrap;
  font-size: $s-scale-5;
  color: $s-color-neutral3;
}

.NetworkPicker .overlay {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  -webkit-animation: fadein 0.4s;
     -moz-animation: fadein 0.4s;
      -ms-animation: fadein 0.4s;
       -o-animation: fadein 0.4s;
          animation: fadein 0.4s;

  &.visible {
    display: block;
  }
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.NetworkPicker .overlay .modal {
  font-weight: 300;
  margin: 10% auto;
  padding: 20px;
  width: 470px;
  background-color: white;
  color: #586A73;
  box-shadow: 0 0 80px #dddddd;
  border-radius: .5rem;

  .right {
    float: right;
  }

  p, button {
    margin-top: 1em;
  }

  a.close {
    color: #000000;
    font-size: 25px;
    line-height: 20px;
    text-decoration: none;
  }
}
