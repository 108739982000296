// General base stylings
input,
select,
.s-button {
  padding: 0;
  margin: 0;
  border-radius: 0;
  background: #fff;
  position: relative;

  &:focus {
    z-index: 1;
  }
}


// Base form stylings
input {
  border: none;
  line-height: 1.5;
}
select {
}
