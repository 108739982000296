@font-face {
  font-display: block;
  font-family: suisse;
  src:
    url('../assets/suisseintl-regular-webfont.woff') format("woff"),
    url('../assets/suisseintl-regular-webfont.woff2') format("woff2");
  font-weight: 400;
}
@font-face {
  font-display: block;
  font-family: suisse;
  src:
    url('../assets/suisseintl-semibold-webfont.woff') format("woff"),
    url('../assets/suisseintl-semibold-webfont.woff2') format("woff2");
  font-weight: 500;
}

@font-face {
  font-display: swap;
  font-family: suisse-mono;
  src:
    url('../assets/suisseintlmono-regular-webfont.woff') format("woff"),
    url('../assets/suisseintlmono-regular-webfont.woff') format("woff2");
  font-weight: 400;
}

html {
  color: #4a4a49;
  font-size: 16px;
}
body {
  font-family: 'suisse', sans-serif;
}
code, kbd, pre, samp {
  font-family: 'suisse-mono', monospace;
}

.so-logo {
  align-items: center;
}
.so-logo__main {
  margin: -12px 0 -7px;
  line-height: 0;
}
.so-logo__separator {
  height: 1em;
  border-color: $gray;
}
.so-back {
  padding-bottom: 1rem;
}
input, textarea, select, .so-dropdown__select {
  border-color: $gray;
}

.so-dropdown__select {
  option {
    color: #3a3f43;
  }
}

.buttonList__item.buttonList__item {
  background-color: transparent;
  border-color: transparent;

  &:hover {
    border-color: #AAAAAA;
  }
}

.s-buttonGroup > {
  .s-button:not(:last-child):not(:first-child),
  .s-buttonGroup__wrapper:not(:last-child):not(:first-child) .s-button {
    border-radius: 0;
  }

  .s-buttonGroup__wrapper:first-of-type .s-button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .s-buttonGroup__wrapper:last-of-type .s-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.s-button--light.is-active,
.s-button__light.is-active,
:checked+.s-button--light,
:checked+.s-button__light {
  background: $purple;
  border: 1px solid $purple;
  color: white;
}
.s-button {
  background: $purple;
  border: 1px solid $purple;
}
.s-button__light, .s-button--light {
  background: $white;
  border-color: $gray;
}
.s-button__icon {
  background: none;
  border: none;
}
.s-button.is-disabled, .s-button:disabled{
  background: $gray;
  border-color: $gray;
}
.s-button:not(.buttonList__item) {
  border-radius: 4rem;

   &:not([disabled]) {

    :checked + & {
      border-color: $purple;
    }

    :not(:checked) + &.s-button--light {
      &:active {
        background-color: $lightPurple;
        border-color: $lightPurple;
      }
    }

     &:hover {
      background-color: #6400CC;
      border-color: #6400CC;
      color: $white;
    }
  }
}
.is-active.is-active {
  border-color: #CCCCCC;
}

.s-alert--info {
  background-color: $lightPurple;
  border-color: $purple;
}
