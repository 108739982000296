.s-alert {
  display: block;
  padding: 0.5em 1em;
  margin-bottom: 1em;
  border-radius: $s-var-radius;
}
.s-alert--warning {
  border: 1px solid $s-color-warning;
  background: whiten($s-color-warning, 80%);
}
.s-alert--info {
  border: 1px solid $s-color-info;
  background: whiten($s-color-info, 85%);
}
.s-alert--alert {
  border: 1px solid $s-color-alert;
  background: whiten($s-color-alert, 80%);
}
.s-alert--success {
  border: 1px solid $s-color-success;
  background: whiten($s-color-success, 80%);
}
.s-alert__close {
  float: right;
  border: 1px solid #333; // TODO: color
  line-height: 1;
  border-radius: 50%;
  background: none;
}


.s-inputAlert--warning {
  border: 1px solid $s-color-warning;
}
.s-inputAlert--info {
  border: 1px solid $s-color-info;
}
.s-inputAlert--alert {
  border: 1px solid $s-color-alert;
}
.s-inputAlert--success {
  border: 1px solid $s-color-success;
}
