.EndpointExplorer {
  margin-top: 2em;
}
.EndpointExplorer__picker {
  margin-bottom: 2em;
}

.EndpointExplorer__setup {
  margin-bottom: 2em;
}

.EndpointExplorer__result {
  margin-bottom: 4em;
}
