.EndpointResult {

}

.EndpointResult__loading,
.EndpointResult__error,
.EndpointResult__content {
  padding: 1em;
}

.EndpointResult__loading {
  border: 1px solid $gray;
  border-radius: $s-var-radius;
}

.EndpointResult__error {
  border: 1px solid $s-color-alert;
  border-radius: $s-var-radius;
}

.EndpointResult__tabs {
  @include S-flex-row;
  border: 1px solid $gray;
  padding: 0.5em 1em 0 1em;
  background: $lightestGray;
}
.EndpointResult__tabs:first-child {
  border-radius: $s-var-radius $s-var-radius 0 0;
}
.EndpointResult__tabs__tab {
  position: relative;
  margin-right: 1em;
  border: 1px solid transparent;
  border-bottom: 0;
  padding: $so-inputPadding;
  background: transparent;
  border-radius: $s-var-radius $s-var-radius 0 0;
}
.EndpointResult__tabs__tab:hover {
  border: 1px solid $s-color-primary6;
  border-bottom: 0;
  background: $s-color-primary9;
}
.EndpointResult__tabs__tab.is-current {
  border: 1px solid $gray;
  border-bottom: 0;
  background: $s-color-neutral9;
}
// removes the border under the tab
// feel free to reconsider whether this vs negative margins is a better approach
.EndpointResult__tabs__tab.is-current:after {
  content: '';
  display: block;
  height: 1px;
  background: $s-color-neutral9;
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
}

.EndpointResult__content {
  border: 1px solid $gray;
  border-top: 0;
  border-radius: 0 0 $s-var-radius $s-var-radius;
  padding: 1em;
}
