.TxSignerResult {
  padding-top: 2em;
  padding-bottom: 2em;
  border-top: 1px solid $s-color-neutral7;
  border-bottom: 1px solid $s-color-neutral7;

  background: $s-color-neutral8;
}
  .TxSignerResult__title {
    color: $s-color-success;
  }
  .TxSignerResult__xdr {
    cursor: pointer;
    margin-bottom: 1.5em;
    background: $s-color-neutral9;
  }
  .TxSignerResult__submit {
    margin-top: 1em;
  }
