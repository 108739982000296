.optionsTable {
  border: 1px solid $gray;
  border-radius: $s-var-radius;
}
  .optionsTable__pair, .optionsTable__separator, .optionsTable__blank {
    width: auto;
  }
  .optionsTable__separator, .optionsTable__blank {
    @include S-flexItem-full;
  }
  .optionsTable__pair {
    border-radius: $s-var-radius;
    @include S-flex-row;
    margin: 0.75em 1em;
    background: $lightestGray;
    border: 1px solid transparent;
  }
  .optionsTable__pair__title {
    min-width: 8em;
    max-width: 12em;
    @include S-flexItem-1of4;
    padding: 1.1em 1em 1em 1em;
  }
    .optionsTable__pair__title__optional {
      font-size: $s-scale-5;
      color: $s-color-neutral4;
    }
  .optionsTable__pair__content {
    @include S-flexItem-share;
    padding: 0.75em 1em;
  }
    .optionsTable__pair__content__note {
      font-size: $s-scale-5;
      margin: 0.75em 0 0 0;
      color: $s-color-neutral3;
    }
    .optionsTable__pair__content__note--alert {
      color: $s-color-alert;
    }
    .optionsTable__pair__content__field {
      margin-bottom: 0.5em;
    }
    .optionsTable__pair__content__field:last-of-type {
      margin-bottom: 0;
    }
  .optionsTable__blank {
    padding: 0.75em 1em;
  }
  .optionsTable__separator {
    height: 1px;
    margin: 0;
    border: 0;
    background: $gray;
  }
